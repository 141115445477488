import * as actionTypes from './actions';
import {backurl} from '../config/env';
import isEmpty from "is-empty";
import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';


const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...backurl,
    isFullScreen: false, // static can't change
    isAuthenticated: false,
    user: {},
    loading: false,
};


const reducer = (state = initialState, action) => {
    

    switch (action.type) {
   

            case actionTypes.SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload
            };
            
        case actionTypes.USER_ADD:
            return {
                ...state,
                user: action.payload
            };
        
        case actionTypes.USER_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;

     
    }
};

const errorState = {};

 const errorFunction = (state = errorState, action) => {
    switch (action.type) {
        case actionTypes.GET_ERRORS:
            return action.payload;
        default:
            return state;
    }
}

const store = createStore(
    combineReducers({
        errorFunction   :   errorFunction,
        reducer         :   reducer
    }),applyMiddleware(thunk),
        
);
export default store;